<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import Pdf from 'vue-pdf'
import axios from "axios";
import appConfig from "../../../../src/app.config";

export default {
  locales: {
    pt: {
      'My Contracts': 'Meus Contratos',
    },
    es: {
      'My Contracts': 'Mis Contratos',
    }
  },
  components: {
    Layout,
    Pdf
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      contract: {
        loading: true,

        id: this.$route.params.id,
        code: '',
        status: '',
        sign: '',
        date: {
          start: '',
          end: '',
        },
        value: '0.00',
        pdf: '',
      },

      order: null,

      invoice: {
        modal: false,
      },

      profitability: {
        loading: true,
        list: [],
        accumulated: '0%',
        average: '0%',
        bonus: '0.00',
      },

      ibovespa: {
        list: [],
        accumulated: '0%',
        average: '0%',
        bonus: '0.00',
      },

      step: 0,

      pdf: {
        loadedRatio: 0,
        page: 1,
        numPages: 0,
        rotate: 0,
      },

      tosign: {
        token: '',
        fields: [
          { length: 1, value: '' },
          { length: 1, value: '' },
          { length: 1, value: '' },
          { length: 1, value: '' },
          { length: 1, value: '' },
          { length: 1, value: '' },
        ],

        alert: {
          type: '',
          message: '',
        },

        loading: false,
        status: false,
      },

      document: {
        loading: false,
        status: false,
        id: null,
        title: null,
        file: null,
      },

      file: null,

      proof: {
        add: false,
        loading: false,
        errored: false,
        status: false,
        list: null,
      },
    };
  },
  methods: {
    getContract: function () {
      this.pdf.loadedRatio = 0
      this.pdf.page = 1
      this.pdf.numPages = 0

      api
        .get('contract/' + this.contract.id)
        .then((response) => {
          if (response.data.status == 'success') {
            this.contract = response.data.contract
            this.contract.loading = false

            this.order = response.data.order

            this.getProfitability()
            this.getFile()
          }
        })
    },
    getProfitability: function () {
      this.profitability.loading = true
      this.profitability.list = []
      this.profitability.accumulated = 0
      this.profitability.average = 0

      api
        .get('contract/' + this.contract.id + '/profitability')
        .then((response) => {
          if (response.data.status == 'success') {
            this.profitability.list = response.data.contract.list
            this.profitability.accumulated = response.data.contract.accumulated
            this.profitability.average = response.data.contract.average
            this.profitability.bonus = response.data.contract.bonus

            this.ibovespa.list = response.data.ibovespa.list
            this.ibovespa.accumulated = response.data.ibovespa.accumulated
            this.ibovespa.average = response.data.ibovespa.average
            this.ibovespa.bonus = response.data.ibovespa.bonus
          }
          this.profitability.loading = false
        })
    },
    nextStep: function (step) {
      this.step = step

      if (step == 2) {
        api.get('contract/' + this.contract.id + '/tosign')
      }
    },
    setToken: function (e) {
      let value = e.target.value;

      if (e.inputType == 'insertFromPaste') {
        this.tosign.fields.forEach((e,k) => {
          if (value.slice(k, k+1)) {
            this.tosign.fields[k].value = value.slice(k, k+1)
          }
        })

        return;
      }

      let index = parseInt(e.target.dataset.index);
      let maxlength = e.target.dataset.length

      if (this.tosign.fields[index].value.length > maxlength) {
        this.tosign.fields[index].value = value.slice(0, 1);

        if (typeof this.tosign.fields[index + 1] == 'undefined') {
          return;
        }

        this.$refs[`input-${parseInt(index + 1)}`][0].focus();
      }

      if (value.length >= maxlength) {
        if (typeof this.tosign.fields[index + 1] == 'undefined') {
          return;
        }

        this.$refs[`input-${parseInt(index + 1)}`][0].focus();
      }

      if (value == '') {
        if (typeof this.tosign.fields[index - 1] == 'undefined') {
          return;
        }

        this.$refs[`input-${parseInt(index - 1)}`][0].focus();
      }
    },
    getToken: function () {
      let token = ''
      this.tosign.fields.forEach(e => {
        token += e.value;
      })
      this.tosign.token = token
    },
    signContract: function () {
      this.tosign.loading = true
      api
        .post('contract/' + this.contract.id + '/tosign', {
          token: this.tosign.token
        })
        .then((response) => {
          if (response.data.status == 'success') {
            this.tosign.alert.type = ''
            this.tosign.alert.message = ''

            this.step = 3

            this.getContract()
          } else {
            this.tosign.alert.type = 'text-danger'
            this.tosign.alert.message = response.data.message
          }
          this.tosign.loading = false
        })
    },
    getFile: function () {
      this.proof.loading = true;

      api
        .get("store/orders/file/" + this.order.id)
        .then((response) => {
          if (response.data.status == "success") {
            this.proof.list = response.data.files;
          } else {
            this.proof.list = null;
          }
        })
        .catch((error) => {
          this.proof.errored = error;
          this.proof.loading = false;
        })
        .finally(() => {
          this.proof.loading = false;
        });
    },
    setFile() {
      this.document.loading = true;

      let formData = new FormData();

      formData.append("file", this.file);

      var config = {
        headers: {
          "x-auth-token": localStorage.token,
          "Content-Type": "multipart/form-data",
        },
      };

      axios
        .post(
          appConfig.apiUrl + "store/orders/file/" + this.order.id,
          formData,
          config
        )
        .then((response) => {
          if (response.data.status == "success") {
            this.getFile();
            this.$noty.success(response.data.message);
            this.document.status = false;
          } else {
            this.$noty.error(response.data.message);
            this.document.status = false;
          }
          this.document.loading = false;
        });
    },
    onFileSelected() {
      this.file = this.$refs.file[0].files[0];
    },
  },
  mounted() {
    this.getContract()
    this.getToken()
  },
  watch: {
    'tosign.fields': {
      handler: function () {
        this.getToken()
      },
      deep: true
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('My Contracts') }}</h4>
        </div>
      </div>
    </div>

    <div v-if="contract.loading" class="text-center">
      <b-spinner small class="ml-2 align-middle" variant="dark" role="status"></b-spinner>
    </div>
    <div v-else>
      <div class="row justify-content-between">
        <div class="col-md-6">
          <div class="card contract text-dark">
            <div class="card-body pb-3">
              <div class="d-flex justify-content-between mb-3">
                <span class="contract-code">
                  <small>CÓDIGO</small><br>
                  #{{ contract.code }}
                </span>
                <b-dropdown right size="sm">
                  <template #button-content>
                    Opções <i class="bx bx-caret-down align-middle"></i>
                  </template>
                  <b-dropdown-item v-on:click="invoice.modal = true"><span>Ver Fatura</span></b-dropdown-item>
                  <b-dropdown-divider v-if="contract.status == 'active'"></b-dropdown-divider>
                  <b-dropdown-item v-if="contract.status == 'active'"  :to="'/contracts/' + contract.id + '/close'"><span class="text-danger">Cancelar</span></b-dropdown-item>
                </b-dropdown>
              </div>
              <hr class="mt-2 mb-2">
              <div class="d-flex justify-content-between">
                <div class="contract-value">
                  <h5 class="mb-0">{{ contract.value | currency }}</h5>
                </div>
                <div class="contract-status">
                  <span v-if="contract.status == 'pending'" class="badge badge-warning p-2 font-size-13 text-uppercase">Pendente</span>
                  <span v-else-if="contract.status == 'inactive'" class="badge badge-primary p-2 font-size-13 text-uppercase">Inativo</span>
                  <span v-else-if="contract.status == 'active'" class="badge badge-success p-2 font-size-13 text-uppercase">Ativo</span>
                  <span v-else-if="contract.status == 'closed'" class="badge badge-dark p-2 font-size-13 text-uppercase">Encerrado</span>
                  <span v-else-if="contract.status == 'deleted'" class="badge badge-danger p-2 font-size-13 text-uppercase">Cancelado</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-modal v-model="invoice.modal" :title="contract.code" centered>
        <div class="text-uppercase font-size-12">Valor do contrato</div>
        <h4>{{ contract.value | currency }}</h4>
        <hr>
        <template v-if="contract.status === 'pending'">
          <b-tabs
            justified
            nav-class="nav-tabs-custom mt-2"
            content-class="p-3 text-muted"
          >
            <b-tab
              v-for="(pay, method) in order.payments"
              :key="method"
            >
              <template v-slot:title>{{ pay.name }}</template>
              <div v-if="method == 'PIX'" class="text-center">
                <img style="width: 250px;" :src="pay.qrcode"/>
                <p>Leia o qrcode acima ou</p>
                <ol class="text-left">
                  <li>Abra o app do seu banco ou seu app de pagamentos.</li>
                  <li>Busque a opção de pagar com pix.</li>
                  <li>Copie e cole o seguinte código.</li>
                  <li>Após o pagamento envie o comprovante da tranferência.</li>
                </ol>

                <div class="border rounded p-3 mb-3 text-left" style="word-break: break-all;">
                  {{ pay.copypaste }}
                </div>

                <button class="btn btn-default" v-clipboard:copy="pay.copypaste"><i class="bx bx-copy font-size-18 align-middle"></i> Copiar</button>

                <hr class="">
                <div class="mt-5 text-center">
                  <h3 class="font-size-16 mb-2">Comprovantes</h3>
                  <p>Após realizar seu pagamento, anexe seu comprovante aqui.</p>
                  <div class="mb-4 text-left" v-for="(prof, index) in proof.list" :key="index">
                    <a target="_blank" :href="prof.url"><i class="bx bx-file h4 m-0 align-top"></i> {{ prof.url.replace('https://s3.mktpay.co/store/', '') }}</a>
                  </div>
                  <div class="text-center p-0">
                    <b-form @submit.prevent="setFile">
                      <div class="input-group">
                        <input type="file" id="file" ref="file" aria-describedby="inputGroupFileAddon04" @change="onFileSelected" aria-label="Upload" class="form-control">
                        <b-button class="border-left-0 px-4" style="border-top-left-radius: 0; border-bottom-left-radius: 0;" type="submit" variant="default" :disabled="document.loading">
                          {{ t('Enviar') }}
                          <b-spinner v-if="document.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                        </b-button>
                      </div>
                    </b-form>
                  </div>
                </div>
              </div>
              <div v-else-if="method == 'USDT'" class="text-center">
                <img alt="" :src="pay.qrcode"><br>
                <p class="font-size-16 mt-0 mb-0">
                  <small class="text-uppercase">{{ t('Value') }}</small><br>
                  <strong>{{ pay.total }}</strong>
                </p>
                <p class="font-size-16 mt-2">
                  <small class="text-uppercase">{{ t('Address') }}</small><br>
                  <strong>{{ pay.address }}</strong>
                </p>
                <p>
                  {{ t('Envie exatamente o valor para efetuar o pagamento') }}
                  <small v-if="pay.expiration"><br>Expira em {{ pay.expiration}}</small>
                </p>

                <p v-if="pay.description" class="text-center font-size-14 font-weight-bold mb-0">
                  {{ pay.description }}
                </p>
              </div>
              <div v-else-if="method == 'mercadopago'" class="text-center">
                <img class="mt-1 mb-4" height="50px" src="@/assets/images/payments/logo-mercadopago.svg"><br>
                <a target="_blank" class="btn btn-default btn-lg" :href="pay.url">{{ t('Clique aqui para pagar') }}</a>
              </div>
            </b-tab>
          </b-tabs>
          <hr>
        </template>
        <div class="row text-center">
          <div class="col-6">
            <div class="text-uppercase font-size-12 mb-2">Status</div>
            <div class="contract-status">
              <span v-if="contract.status == 'pending'" class="badge badge-warning p-2 font-size-13 text-uppercase">Pendente</span>
              <span v-else-if="contract.status == 'inactive'" class="badge badge-primary p-2 font-size-13 text-uppercase">Inativo</span>
              <span v-else-if="contract.status == 'active'" class="badge badge-success p-2 font-size-13 text-uppercase">Ativo</span>
              <span v-else-if="contract.status == 'closed'" class="badge badge-dark p-2 font-size-13 text-uppercase">Encerrado</span>
              <span v-else-if="contract.status == 'deleted'" class="badge badge-danger p-2 font-size-13 text-uppercase">Cancelado</span>
            </div>
          </div>
          <div class="col-6">
            <div class="text-uppercase font-size-12 mb-2">Assinatura</div>
            <div class="contract-sign">
              <span v-if="contract.sign == 'pending'" class="badge badge-warning p-2 font-size-13 text-uppercase">Pendente</span>
              <span v-else-if="contract.sign == 'signed'" class="badge badge-success p-2 font-size-13 text-uppercase">Assinado</span>
              <span v-else-if="contract.sign == 'refused'" class="badge badge-dark p-2 font-size-13 text-uppercase">Recusado</span>
            </div>
          </div>
        </div>
        <template v-slot:modal-footer>
          <div></div>
        </template>
      </b-modal>

      <div class="card">
        <div class="card-body pt-2">
          <b-tabs justified nav-class="nav-tabs-custom" content-class="pt-3">
            <b-tab active>
              <template v-slot:title>
                Contrato
              </template>
              <div v-if="step == 0">
                <div v-if="contract.id">
                  <div class="row justify-content-center">
                    <div class="col-md-8 text-center">
                      <div class="border shadow rounded">
                        <div class="d-md-flex justify-content-between bg-soft-dark p-2 rounded-top">
                          <div>
                            <button class="btn btn-default rounded-circle p-0 mr-2" :class="{'disabled' : pdf.page == 1}" @click="pdf.page == 1 ? 1 : pdf.page--"><i class="bx bx-chevron-left text-white h1 p-0 m-0 align-middle"></i></button>
                            <button class="btn btn-default rounded-circle p-0" :class="{'disabled' : pdf.page == pdf.numPages}" @click="pdf.page == pdf.numPages ? pdf.numPages : pdf.page++"><i class="bx bx-chevron-right text-white h1 p-0 m-0 align-middle"></i></button>
                          </div>
                          <div class="align-self-center py-2">Página {{ pdf.page }} de {{ pdf.numPages }}</div>
                          <div>
                            <a :href="contract.pdf + '/download'" class="btn btn-default rounded-circle p-2 mr-2"><i class="bx bxs-download font-size-20 align-middle"></i></a>
                            <button class="btn btn-default rounded-circle p-2" @click="$refs.pdf.print()"><i class="bx bx-printer font-size-20 align-middle" ></i></button>
                          </div>
                        </div>
                        <div v-if="pdf.loadedRatio == 0" class="py-4"><b-spinner small class="ml-2 align-middle" variant="dark" role="status"></b-spinner></div>
                        <pdf ref="pdf" :src="contract.pdf" :page="pdf.page" @progress="pdf.loadedRatio = $event" @num-pages="pdf.numPages = $event" @link-clicked="pdf.page = $event"></pdf>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="text-center py-5">
                    <i class="far fa-sad-cry font-size-24"></i><br>
                    o contrato não está disponível
                  </div>
                </div>
                <div v-if="contract.sign == 'pending'" class="text-center my-4">
                  <button class="btn btn-primary btn-lg px-5" @click="nextStep(1)">Assinar</button>
                </div>
              </div>
              <div v-else-if="step == 1">
                <div class="border-bottom d-flex justify-content-between mb-4 p-2">
                  <div class="align-self-center">
                    <button class="btn btn-link p-0 text-dark" @click="nextStep(0)"><i class="bx bx-arrow-back font-size-22 text-dark text-decoration-none align-top"></i> <span class="d-none d-sm-inline-block">Voltar</span></button>
                  </div>
                  <div class="align-self-center">
                    Etapa 1 de 2
                  </div>
                  <div class="align-self-center">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                </div>

                <div class="row justify-content-center">
                  <div class="col-md-7 mb-4">
                    <h3 class="text-center mb-4">Confirme seus dados</h3>
                    <p class="m-0">Nome completo</p>
                    <h5 class="font-weight-bold mb-4">{{ account.user.name }}</h5>
                    <p v-if="account.user.person.type == 'natural'" class="m-0">CPF</p>
                    <p v-if="account.user.person.type == 'legal'" class="m-0">CNPJ</p>
                    <h5 class="font-weight-bold mb-4">{{ account.user.person.number }}</h5>
                    <p v-if="account.user.birthday" class="m-0">Data de nascimento</p>
                    <h5 v-if="account.user.birthday" class="font-weight-bold mb-4">{{ account.user.birthday }}</h5>
                    <button class="btn btn-primary btn-block btn-lg px-5" @click="nextStep(2)">Avançar <i class="bx bx-right-arrow-alt font-size-24 align-middle"></i></button>
                    <div class="mt-2 text-center text-muted">
                      <i class="bx bx-lock-alt font-size-18 align-top"></i> Ambiente seguro
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="step == 2">
                <div class="border-bottom d-flex justify-content-between mb-4 p-2">
                  <div class="align-self-center">
                    <button class="btn btn-link p-0 text-dark" @click="nextStep(1)"><i class="bx bx-arrow-back font-size-22 text-dark text-decoration-none align-top"></i> <span class="d-none d-sm-inline-block">Voltar</span></button>
                  </div>
                  <div class="align-self-center">
                    Etapa 2 de 2
                  </div>
                  <div class="align-self-center">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                </div>

                <div class="row justify-content-center">
                  <div class="col-md-7 mb-4 text-center">
                    <h4>Enviamos um token para o e-mail:</h4>
                    <h5 class="font-weight-normal text-primary mb-3">{{ account.user.email.address }}</h5>
                    <p class="m-0">Insira o token para finalizar</p>
                    <div class="token mt-1 mb-3 d-flex justify-content-center">
                      <input
                        type="text"
                        class="form-control"
                        v-for="(key, i) in tosign.fields"
                        :key="i"
                        :data-length="key.length"
                        :data-index="i"
                        :ref="`input-${i}`"
                        v-model="key.value"
                        v-on:input="setToken($event)"
                      />
                    </div>
                    <div v-if="tosign.alert.message" class="mb-3" :class="tosign.alert.type">{{ t(tosign.alert.message) }}</div>
                    <button class="btn btn-primary btn-block btn-lg px-5 mb-3" @click="signContract()">
                      Finalizar
                      <b-spinner v-if="tosign.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                    </button>
                    <div>
                      <p>Não recebeu o token? Reenviar token via email</p>
                      <!-- <p>Ao clicar em finalizar, você concorda com os Termos de uso e Política de Privacidade - LGPD da Clicksign.</p> -->
                    </div>
                    <div class="mt-2 text-center text-muted">
                      <i class="bx bx-lock-alt font-size-18 align-top"></i> Ambiente seguro
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="step == 3">
                <div class="row justify-content-center">
                  <div class="col-md-7 my-4 text-center">
                    <img style="width:300px;" src="@/assets/images/icon-contract.svg" />
                    <h2 class="font-weight-bold mt-4 mb-0">Pronto!</h2>
                    <h2>Documento assinado.</h2>
                    <div class="mt-2 text-center text-muted">
                      <i class="bx bx-lock-alt font-size-18 align-top"></i> Ambiente seguro
                    </div>
                  </div>
                </div>
                <div class="text-center my-4">
                  <button class="btn btn-primary btn-lg px-5" @click="nextStep(0); this.pdf.loadedRatio = 0; this.pdf.loadedRatio.page; ; this.pdf.loadedRatio.numPages = 0;">Ver Contrato</button>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.contract {
  border-left: solid 5px #090a09;
}
.contract-code {
  line-height: 14px;
}

.table-profit {
  line-height: 15px;
}
.table-profit th,
.table-profit td {
  white-space: nowrap;
}
.table-year th {
  background: #BEBFC1;
}
.table-month th {
  background: #D2D3D4;
}

.token input {
  margin: 0 2px;
  width: 40px;
  height: 40px;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}
</style>